<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <edit-tab
      :item-data="userData"
      :lista-empresas="listaEmpresas"
      class="mt-1"
    />
  </component>
</template>

<script>
import {
  BAlert, BCard, BLink, BTab, BTabs,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import clienteStoreModule from '../clienteStoreModule'
import EditTab from './EditTab.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    EditTab,
  },
  setup() {
    const userData = ref({})

    const CLIENTES_APP_STORE_MODULE_NAME = 'app-clientes'

    // Register module
    if (!store.hasModule(CLIENTES_APP_STORE_MODULE_NAME)) store.registerModule(CLIENTES_APP_STORE_MODULE_NAME, clienteStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENTES_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENTES_APP_STORE_MODULE_NAME)
    })

    const listaEmpresas = ref([])
    store.dispatch('app-clientes/fetchEmpresas')
      .then(response => {
        listaEmpresas.value = response.data
      })

    store.dispatch('app-clientes/show', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })
    return {
      userData,
      listaEmpresas,
    }
  },
}
</script>
