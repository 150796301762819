<template>
  <div>
    <b-row class="mb-2">
      <b-col
        col
        lg="6"
      >
        <h4> Dados Gerais </h4>
        <small>Cliente: {{ itemData.razao_social }}</small>
      </b-col>

      <b-col
        col
        class="text-right"
        lg="6"
      >
        <img
          :src="itemData.imagem"
          height="90"
        >
      </b-col>
    </b-row>
    <hr>
    <b-form>
      <b-row>

        <!-- Field: razao_social -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Razão Social / Nome"
            label-for="razao_social"
          >
            <b-form-input
              id="razao_social"
              v-model="dadosCliente.razao_social"
              placeholder="Informe o nome da empresa"
            />
          </b-form-group>
        </b-col>
        <!-- Field: CNPJ -->
        <b-col
          cols="12"
          md="2"
        >
          <label> Tipo de cliente</label>
          <div class="d-flex mb-2">
            <b-form-radio
              v-model="tipoCliente"
              class="mr-2"
              name="some-radios"
              value="PJ"
            >
              PJ
            </b-form-radio>
            <b-form-radio
              v-model="tipoCliente"
              name="some-radios"
              value="PF"
            >
              PF
            </b-form-radio>

          </div>
        </b-col>
        <b-col
          v-show="tipoCliente === 'PJ'"
          cols="12"
          md="3"
        >
          <b-form-group
            label="CNPJ"
            label-for="cnpj"
          >
            <b-form-input
              id="cnpj"
              v-model="dadosCliente.cnpj"
              v-mask="'##.###.###/####-##'"
              placeholder="00.000.000/0000-00"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-show="tipoCliente === 'PF'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="CPF"
            label-for="cpf"
          >
            <b-form-input
              id="cpf"
              v-model="dadosCliente.cpf"
              v-mask="'###.###.###-##'"
              placeholder="000.000.000-00"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Empresa -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Empresa que o cliente pertence"
            label-for="empresa"
          >
            <v-select
              v-model="itemData.empresas"
              :options="listaEmpresas"
              label="nome"
              :reduce="val => val.uuid"
              :clearable="false"
              input-id="user-empresa"
              placeholder="Escolha uma empresa"
              multiple
            >
              <template v-slot:no-options>
                Nenhuma opção encontrada
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <!-- Field: Telefone -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Telefone"
            label-for="telefone"
          >
            <b-form-input
              id="telefone"
              v-model="itemData.telefone"
              v-mask="'(##) # ####-####'"
              placeholder="(00) 0 0000-0000"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="E-mail"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="itemData.email"
              type="email"
              placeholder="nome@exemplo.com"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Contato Responsável"
            label-for="contato_responsavel"
          >
            <b-form-input
              id="contato_responsavel"
              v-model="itemData.contato_responsavel"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Nome Relatório"
            label-for="nome_relatorio"
          >
            <b-form-input
              id="nome_relatorio"
              v-model="itemData.nome_relatorio"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Logo da Empresa"
            label-for="logo"
          >
            <b-form-file
              v-model="itemData.logo"
              label="Logo"
              label-for="logo"
              browse-text="Procurar"
              placeholder="Escolha um arquivo"
              drop-placeholder="Escolha um arquivo"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col
          col
          lg="2"
        >
          <h4> Endereço </h4>
        </b-col>

      </b-row>
      <hr>
      <b-row v-if="itemData.endereco">
        <!-- Field: CEP -->
        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="CEP"
            label-for="cep"
          >
            <b-form-input
              id="cep"
              v-model="itemData.endereco.cep"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Logradouro -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Logradouro"
            label-for="logradouro"
          >
            <b-form-input
              id="logradouro"
              v-model="itemData.endereco.logradouro"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Número -->
        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="Número"
            label-for="num_endereco"
          >
            <b-form-input
              id="num_endereco"
              v-model="itemData.endereco.num_endereco"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Complemento -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Complemento"
            label-for="complemento"
          >
            <b-form-input
              id="complemento"
              v-model="itemData.endereco.complemento"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Bairro -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Bairro"
            label-for="bairro"
          >
            <b-form-input
              id="bairro"
              v-model="itemData.endereco.bairro"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Cidade -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Cidade"
            label-for="cidade"
          >
            <b-form-input
              id="cidade"
              v-model="itemData.endereco.cidade"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Estado -->
        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="Estado"
            label-for="estado"
          >
            <b-form-input
              id="estado"
              v-model="itemData.endereco.estado"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>
    <!-- Action Buttons -->
    <b-col class="text-right pr-0">
      <b-button
        variant="outline-secondary"
        type="button"
        :to="{ name: 'clientes' }"
      >
        Voltar
      </b-button>
      <b-button
        type="button"
        variant="success"
        class="mb-1 mb-sm-0 mr-0 ml-sm-1"
        @click="editarCliente(itemData)"
      >
        Salvar
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton, BCol, BForm, BFormFile, BFormGroup, BFormInput, BFormRadio, BRow,
} from 'bootstrap-vue'

import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {

  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BFormFile,
    BFormRadio,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    listaEmpresas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tipoCliente: 'PJ',
      dadosCliente: null,
    }
  },
  watch: {
    tipoCliente(val) {
      this.dadosCliente.cnpj = ''
      this.dadosCliente.cpf = ''
    },
    itemData: {
      handler(newItemData) {
        this.dadosCliente = { ...newItemData }
        if (this.dadosCliente.cnpj) {
          this.tipoCliente = 'PJ'
        } else {
          this.tipoCliente = 'PF'
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.dadosCliente = this.itemData
  },
  created() {
    this.dadosCliente = { ...this.itemData } // Copie o valor da prop itemData para itemDataCopy
  },

  methods: {
    editarCliente() {
      store.dispatch('app-clientes/update', this.dadosCliente)
        .then(respo => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cliente editado com sucesso',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            const listaErros = error.response.data.errors
            for (const field in listaErros) {
              if (listaErros.hasOwnProperty(field)) {
                const errorMessages = listaErros[field]
                errorMessages.forEach(errorMessage => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: errorMessage,
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  })
                })
              }
            }
          }
        })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
